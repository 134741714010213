import Vue from "vue";
import Router from "vue-router";
import Home from "./views/Home.vue";
import Menu from "./views/Menu.vue";

import OpportunityStandardSearch from "./views/opportunities/OpportunityStandardSearch.vue";
import ArchivedOpportunityStandardSearch from "./views/opportunities/ArchivedOpportunityStandardSearch.vue";
import OpportunityView from "./views/opportunities/OpportunityView.vue";
import OpportunityIdView from "./views/opportunities/OpportunityIdView.vue";
import OpportunityCommentCreate from "./views/opportunities/OpportunityCommentCreate.vue";
import OpportunitySuccess from "./views/opportunities/OpportunitySuccess.vue";
import OpportunityCreate from "./views/opportunities/OpportunityCreate.vue";
import OpportunityUpdate from "./views/opportunities/OpportunityUpdate.vue";

import ActionStandardSearch from "./views/actions/ActionStandardSearch.vue";
import ActionView from "./views/actions/ActionView.vue";
import ActionCommentCreate from "./views/actions/ActionCommentCreate.vue";
import ActionSuccess from "./views/actions/ActionSuccess.vue";

import EnergyGuideStandardSearch from "./views/energy_guides/EnergyGuideStandardSearch.vue";
import EnergyGuideUpload from "./views/energy_guides/EnergyGuideUpload.vue";
import PdfReportStandardSearch from "./views/pdf_reports/PdfReportStandardSearch.vue";
import PdfReportUpload from "./views/pdf_reports/PdfReportUpload.vue";

import SEUDashboards from "./views/dashboards/SEUDashboards.vue";

import CapacityTool from "./views/capacity_tool/CapacityTool.vue";

import SiteReports from "./views/sites/SiteReports.vue";

import UserProfileView from "./views/users/UserProfileView.vue"
import UserProfileSuccess from "./views/users/UserProfileSuccess.vue"

import UserChoiceView from "./views/permissions/UserChoiceView.vue"
import UserPermissionsView from "./views/permissions/UserPermissionsView.vue"

import SiteChoiceView from "./views/permissions/SiteChoiceView.vue"
import SiteServicesView from "./views/permissions/SiteServicesView.vue"


import CorporatePlanCarbonTracker from "./views/plans/corporate_plans/CorporatePlanCarbonTracker.vue"
import CorporatePlanStandardSearch from "./views/plans/corporate_plans/CorporatePlanStandardSearch.vue";
import CorporatePlanView from "./views/plans/corporate_plans/CorporatePlanView.vue";
import CorporatePlanCreate from "./views/plans/corporate_plans/CorporatePlanCreate.vue";
import AttachRemoveCorporateSitePlan from "./views/plans/corporate_plans/AttachRemoveCorporateSitePlan.vue";
import CorporatePlanSuccess from "./views/plans/corporate_plans/CorporatePlanSuccess.vue";
import CorporatePlanSuccessReload from "./views/plans/corporate_plans/CorporatePlanSuccessReload.vue";
import CorporatePlanUpdate from "./views/plans/corporate_plans/CorporatePlanUpdate.vue";
import CorporatePlanRefLineUpdate from "./views/plans/corporate_plans/CorporatePlanRefLineUpdate.vue";
import CorporatePlanDelete from "./views/plans/corporate_plans/CorporatePlanDelete.vue";

import DivisionPlanCarbonTracker from "./views/plans/division_plans/DivisionPlanCarbonTracker.vue"
import DivisionPlanStandardSearch from "./views/plans/division_plans/DivisionPlanStandardSearch.vue";
import DivisionPlanView from "./views/plans/division_plans/DivisionPlanView.vue";
import DivisionPlanCreate from "./views/plans/division_plans/DivisionPlanCreate.vue";
import AttachRemoveDivisionSitePlan from "./views/plans/division_plans/AttachRemoveDivisionSitePlan.vue";
import DivisionPlanSuccess from "./views/plans/division_plans/DivisionPlanSuccess.vue";
import DivisionPlanSuccessReload from "./views/plans/division_plans/DivisionPlanSuccessReload.vue";
import DivisionPlanUpdate from "./views/plans/division_plans/DivisionPlanUpdate.vue";
import DivisionPlanRefLineUpdate from "./views/plans/division_plans/DivisionPlanRefLineUpdate.vue";
import DivisionPlanDelete from "./views/plans/division_plans/DivisionPlanDelete.vue";

import SitePlanCarbonTracker from "./views/plans/site_plans/SitePlanCarbonTracker.vue"
import SitePlanCreate from "./views/plans/site_plans/SitePlanCreate.vue";
import SitePlanCopy from "./views/plans/site_plans/SitePlanCopy.vue";
import SitePlanStandardSearch from "./views/plans/site_plans/SitePlanStandardSearch.vue";
import SitePlanView from "./views/plans/site_plans/SitePlanView.vue";
import SitePlanDelete from "./views/plans/site_plans/SitePlanDelete.vue";
import SitePlanUpdate from "./views/plans/site_plans/SitePlanUpdate.vue";
import KanbanBoard from "./views/plans/site_plans/KanbanBoard.vue";
import PlanSuccess from "./views/plans/site_plans/PlanSuccess.vue";
import PlanSuccessReload from "./views/plans/site_plans/PlanSuccessReload.vue";
import AttachRemoveOpportunity from "./views/plans/site_plans/AttachRemoveOpportunity.vue";
import ReschedulePlan from "./views/plans/site_plans/ReschedulePlan.vue";
import SitePlanToPDFView from "./views/plans/site_plans/SitePlanToPDFView.vue";
import SitePlanArchiveStandardSearch from "./views/plans/site_plans/SitePlanArchiveStandardSearch.vue";

import AlarmCreate from "./views/alarms/AlarmCreate.vue";
import AlarmStandardSearch from "./views/alarms/AlarmStandardSearch.vue";
import AlarmUpdate from "./views/alarms/AlarmUpdate.vue";
import AlarmSuccess from "./views/alarms/AlarmSuccess.vue";
import AlarmDelete from "./views/alarms/AlarmDelete.vue";

import PowerBISelectView from "./views/power_bi/PowerBISelectView.vue";
import EnergyEyeSelectView from "./views/power_bi/EnergyEyeSelectView.vue";

import Eva from "./views/ai/Eva.vue";
import EvaOpportunities from "./views/ai/EvaOpportunities.vue";

import ActionReport from "./views/reports/ActionReport.vue";

import NotFound from "./views/NotFound.vue";


Vue.use(Router)

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "home",
      component: Home
    },
    {
      path: "/menu",
      name: "menu",
      component: Menu
    },
    {
      path: "/action-report",
      name: "action-report",
      component: ActionReport
    },
    {
      path: "/opportunity-search",
      name: "opportunity-search",
      component: OpportunityStandardSearch
    },
    {
      path: "/opportunity-archive-search",
      name: "opportunity-archive-search",
      component: ArchivedOpportunityStandardSearch
    },
    {
      path: "/opportunity/:id",
      name: "opportunity-view",
      component: OpportunityView,
      props: true
    },
    {
      path: "/opportunity-details/id/:opportunity_number",
      name: "opportunity-id-view",
      component: OpportunityIdView,
      props: true
    },
    {
      path: "/opportunity-create",
      name: "opportunity-create",
      component: OpportunityCreate,
    },
    {
      path: "/opportunity-update/:id",
      name: "opportunity-update",
      component: OpportunityUpdate,
      props: true
    },
    {
      path: "/opportunity-comment/:id",
      name: "opportunity-comment-create",
      component: OpportunityCommentCreate,
      props: true
    },
    {
      path: "/opportunity-success/:id",
      name: "opportunity-success",
      component: OpportunitySuccess,
      props: true
    },
    {
      path: "/site-reports",
      name: "site-reports",
      component: SiteReports
    },
    {
      path: "/action-search",
      name: "action-search",
      component: ActionStandardSearch
    },
    {
      path: "/action/:id",
      name: "action-view",
      component: ActionView,
      props: true
    },
    {
      path: "/action-comment/:id",
      name: "action-comment-create",
      component: ActionCommentCreate,
      props: true
    },
    {
      path: "/action-success/:id",
      name: "action-success",
      component: ActionSuccess,
      props: true
    },
    {
      path: "/seu-dashboards/",
      name: "seu-dashboards",
      component: SEUDashboards,
    },
    {
      path: "/search/energy-guides",
      name: "energy-guide-search",
      component: EnergyGuideStandardSearch
    },
    {
      path: "/upload-energy-guide",
      name: "upload-energy-guide",
      component: EnergyGuideUpload,
    },
    {
      path: "/search/pdf-reports",
      name: "pdf-report-search",
      component: PdfReportStandardSearch,
    },
    {
      path: "/upload-pdf-report",
      name: "upload-pdf-report",
      component: PdfReportUpload,
    },
    {
      path: "/capacity-tool",
      name: "capacity-tool",
      component: CapacityTool,
    },
    {
      path: "/user-profile",
      name: "user-profile",
      component: UserProfileView,
    },
    {
      path: "/profile-success",
      name: "profile-success",
      component: UserProfileSuccess,
    },
    {
      path: "/site-choice",
      name: "site-choice",
      component: SiteChoiceView,
    },
    {
      path: "/services/:site_code",
      name: "site-services",
      component: SiteServicesView,
      props: true
    },
    {
      path: "/user-permissions",
      name: "user-permissions-choice",
      component: UserChoiceView,
    },
    {
      path: "/user-permissions/:id",
      name: "user-permissions",
      component: UserPermissionsView,
      props: true
    },
    {
      path: "/plan-success/:id",
      name: "plan-success",
      component: PlanSuccess,
      props: true
    },
    {
      path: "/update-success/:id",
      name: "update-success",
      component: PlanSuccessReload,
      props: true
    },

    {
      path: "/corporate-plan-search",
      name: "corporate-plan-search",
      component: CorporatePlanStandardSearch
    },
    {
      path: "/corporate-plan-create",
      name: "corporate-plan-create",
      component: CorporatePlanCreate
    },
    {
      path: "/corporate-plan/:tab/:id",
      name: "corporate-plan-view",
      component: CorporatePlanView,
      props: true
    },
    {
      path: "/attach-remove-corporate-site-plan/:id/",
      name: "attach-remove-corporate-site-plan",
      component: AttachRemoveCorporateSitePlan,
      props: true
    },
        {
      path: "/corporate-plan-delete/:id",
      name: "corporate-plan-delete",
      component: CorporatePlanDelete,
      props: true
    },
    {
      path: "/corporate-plan-update/:id",
      name: "corporate-plan-update",
      component: CorporatePlanUpdate,
      props: true
    },
    {
      path: "/corporate-plan-ref-line-update/:id",
      name: "corporate-plan-ref-line-update",
      component: CorporatePlanRefLineUpdate,
      props: true
    },
    {
      path: "/corporate-plan-success/:id",
      name: "corporate-plan-success",
      component: CorporatePlanSuccess,
      props: true
    },
    {
      path: "/corporate-plan-update-success/:id",
      name: "corporate-plan-update-success",
      component: CorporatePlanSuccessReload,
      props: true
    },

    {
      path: "/division-plan-search",
      name: "division-plan-search",
      component: DivisionPlanStandardSearch
    },
    {
      path: "/division-plan-create",
      name: "division-plan-create",
      component: DivisionPlanCreate
    },
    {
      path: "/division-plan/:tab/:id",
      name: "division-plan-view",
      component: DivisionPlanView,
      props: true
    },
    {
      path: "/attach-remove-division-site-plan/:id/",
      name: "attach-remove-division-site-plan",
      component: AttachRemoveDivisionSitePlan,
      props: true
    },
        {
      path: "/division-plan-delete/:id",
      name: "division-plan-delete",
      component: DivisionPlanDelete,
      props: true
    },
    {
      path: "/division-plan-update/:id",
      name: "division-plan-update",
      component: DivisionPlanUpdate,
      props: true
    },
    {
      path: "/division-plan-ref-line-update/:id",
      name: "division-plan-ref-line-update",
      component: DivisionPlanRefLineUpdate,
      props: true
    },
    {
      path: "/division-plan-success/:id",
      name: "division-plan-success",
      component: DivisionPlanSuccess,
      props: true
    },
    {
      path: "/division-plan-update-success/:id",
      name: "division-plan-update-success",
      component: DivisionPlanSuccessReload,
      props: true
    },

    {
      path: "/site-plan-search",
      name: "site-plan-search",
      component: SitePlanStandardSearch
    },
    {
      path: "/site-plan/:tab/:id",
      name: "site-plan-view",
      component: SitePlanView,
      props: true
    },
    {
      path: "/site-plan-create",
      name: "site-plan-create",
      component: SitePlanCreate
    },
    {
      path: "/site-plan-copy/:id",
      name: "site-plan-copy",
      component: SitePlanCopy,
      props: true
    },
    {
      path: "/site-plan-update/:id",
      name: "site-plan-update",
      component: SitePlanUpdate,
      props: true
    },
    {
      path: "/site-plan-delete/:id",
      name: "site-plan-delete",
      component: SitePlanDelete,
      props: true
    },
    {
      path: "/attach-remove-opportunity/:id/",
      name: "attach-remove-opportunity",
      component: AttachRemoveOpportunity,
      props: true
    },
    {
      path: "/site-plan-archive-search",
      name: "site-plan-archive-search",
      component: SitePlanArchiveStandardSearch
    },
    {
      path: "/kanban-dashboard/:id/",
      name: "kanban-board",
      component: KanbanBoard,
      props: true
    },
    {
      path: "/reschedule/:id/",
      name: "reschedule",
      component: ReschedulePlan,
      props: true
    },

    {
      path: "/site-plan-carbon-tracker/:id/",
      name: "site-plan-carbon-tracker",
      component: SitePlanCarbonTracker,
      props: true
    },
    {
      path: "/division-plan-carbon-tracker/:id/",
      name: "division-plan-carbon-tracker",
      component: DivisionPlanCarbonTracker,
      props: true
    },
    {
      path: "/corporate-plan-carbon-tracker/:id/",
      name: "corporate-plan-carbon-tracker",
      component: CorporatePlanCarbonTracker,
      props: true
    },

    {
      path: "/site-plan-pdf/:id",
      name: "site-plan-pdf",
      component: SitePlanToPDFView,
      props: true
    },

    {
      path: "/alarm-search",
      name: "alarm-search",
      component: AlarmStandardSearch
    },
    {
      path: "/alarm-create",
      name: "alarm-create",
      component: AlarmCreate,
    },
    {
      path: "/alarm-update/:id",
      name: "alarm-update",
      component: AlarmUpdate,
      props: true
    },
    {
      path: "/alarm-delete/:id",
      name: "alarm-delete",
      component: AlarmDelete,
      props: true
    },
    {
      path: "/alarm-success/:id",
      name: "alarm-success",
      component: AlarmSuccess,
      props: true
    },
    {
      path: "/eva",
      name: "eva",
      component: Eva,
      props: true
    },
    {
      path: "/eva-opportunities",
      name: "eva-opportunities",
      component: EvaOpportunities,
      props: true
    },
    {
      path: "/powerbi-select/:site",
      name: "powerbi-select",
      component: PowerBISelectView,
      props: true
    },
    {
      path: "/energyeye-select",
      name: "energyeye-select",
      component: EnergyEyeSelectView,
      props: true
    },

    {
      path: "*",
      name: "page-not-found",
      component: NotFound,
    },
  ]
})
