import Vuex from 'vuex';
import Vue from 'vue';

import actions from './modules/actions';
import alarms from './modules/alarms';
import capacity from './modules/capacity';
import charts from './modules/charts';
import common from './modules/common';
import company from './modules/company';
import dashboards from './modules/dashboards';
import enpi from './modules/enpi';
import eva from './modules/eva';
import metrics from './modules/metrics';
import opportunities from './modules/opportunities';
import pdf_reports from './modules/pdf_reports';
import site_plans from "./modules/site_plans";
import division_plans from "./modules/division_plans";
import corporate_plans from "./modules/corporate_plans";
import powerbi from "./modules/powerbi";

import user from './modules/user';

// Connect Vue with Vuex
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    actions,
    alarms,
    capacity,
    charts,
    common,
    company,
    dashboards,
    enpi,
    eva,
    metrics,
    opportunities,
    pdf_reports,
    powerbi,
    site_plans,
    division_plans,
    corporate_plans,
    user,
  }
});