import api from "../../api/api.js"
import router from "../../router.js"

const state = {
  opportunities: [],
  archivedOpportunities: [],
  opportunityData: null,
  opportunityId: "",
  predecessors: null,
  conflicts: null,
  siteData: null,
  savingsChartData: {},
  savingsChartHeight: null,
  co2ChartData: {},
  co2ChartHeight: null,
  capitalInvestmentChartData: {},
  capitalInvestmentChartHeight: null,
  siteSeuData: null,
  savingsSum: null,
  co2TonnesSum: null,
  capitalInvestmentSum: null,
  opportunityStatusDetails: null,
  opportunityComments: null,

};


const getters = {

  getOpportunities: state => state.opportunities,
  getArchivedOpportunities: state => state.archivedOpportunities,
  getOpportunityData: state => state.opportunityData,
  getOpportunityId: state => state.opportunityId,
  getPredecessors: state => state.predecessors,
  getConflicts: state => state.conflicts,

  getSiteData: state => state.siteData,
  getSavingsChartData: state => state.savingsChartData,
  getSavingsChartHeight: state => state.savingsChartHeight,
  getCo2ChartData: state => state.co2ChartData,
  getCo2ChartHeight: state => state.co2ChartHeight,
  getCapitalInvestmentChartData: state => state.capitalInvestmentChartData,
  getCapitalInvestmentChartHeight: state => state.capitalInvestmentChartHeight,

  getSiteSeuData: state => state.siteSeuData,

  getSavingsSum: state => state.savingsSum,
  getCo2TonnesSum: state => state.co2TonnesSum,
  getCapitalInvestmentSum: state => state.capitalInvestmentSum,

  getOpportunityStatusDetails: state => state.opportunityStatusDetails,

  getOpportunityComments: state => state.opportunityComments,


};


const actions = {
  async fetchAllOpportunities({commit}) {
    commit("setLoader", true);
    const response = await api.getAllOpportunities();
    if (!response)  {
      commit("setFormError", "Something went wrong. Try again later")
      commit("setLoader", false);
    } else {
      commit("setOpportunities", response.queryset);
      commit("setArchivedOpportunities", response.queryset_archive);
      commit("setResultCount", response.queryset.length);
      var savingsSum = (response.queryset.map(a => a.savings_eur)).reduce((a, b) => a + b, 0)
      var co2TonnesSum = (response.queryset.map(a => a.co2_tonnes)).reduce((a, b) => a + b, 0)
      var capitalInvestmentSum = (response.queryset.map(a => a.capital_investment_eur)).reduce((a, b) => a + b, 0)
      commit("setSavingsSum", savingsSum);
      commit("setCo2TonnesSum", co2TonnesSum);
      commit("setCapitalInvestmentSum", capitalInvestmentSum);
       commit("setLoader", false);
    }
  },
  async performUpdateCalculations({commit}, payload) {
      commit("setSavingsSum", payload.savingsSum);
      commit("setCo2TonnesSum", payload.co2TonnesSum);
      commit("setCapitalInvestmentSum", payload.capitalInvestmentSum);
  },
  async fetchOpportunitiesQueryset({commit, dispatch}, payload) {
    dispatch ("clearOpportunityList");
    if (!payload.searchQuery) {
      commit("setFormError", "Search Field can't be empty or too short");
      commit("setOpportunities", []);
      commit("setNext", null);
      commit("setCurrentSearch", null);
      commit("setResultCount", null);
      commit("setLoader", false);
      setTimeout(() => document.getElementById("formError").style.display = "none", 5000);
    }
    else {
      commit("setFormError", null);
      if (state.currentSearch !== payload.searchQuery) {
        commit("setNext", null);
        commit("setLoader", false);
      }
      if (state.next) {
        const response = await api.nextPage(state.next);
        commit("setOpportunities", response.results);
        commit("setResultCount", response.count);
        commit("setLoader", false);
        commit("setCurrentSearch", payload.searchQuery);
        if (response.next) {
          commit("setNext", response.next);
        }
        else {
          commit("setCurrentSearch", null);
        }
      }
      else {
        try {
          commit("setOpportunities", []);
          commit("setLoader", true);
          var response
          if (payload.queryType === "keyword") {
            response = await api.searchOpportunityQuery(payload.searchQuery);
          } else if (payload.queryType === "site"){
            response = await api.siteOpportunities(payload.searchQuery);
          }
          else if (payload.queryType === "site_code"){
            response = await api.siteCodeOpportunities(payload.searchQuery);
          } else {
            response = await api.seuOpportunities(payload.searchQuery);
          }
          commit("setOpportunities", response.results);
          commit("setResultCount", response.count);
          commit("setLoader", false);
          commit("setCurrentSearch", payload.searchQuery)
          if (response.next) {
            commit("setNext", response.next);
          }
          else {
            commit("setNext", null);
          }
        } catch (err) {
          commit("setOpportunities", []);
          commit("setNext", null);
          commit("setCurrentSearch", null);
          commit("setResultCount", null);
          commit("setFormError", "Internal Server Error");
          commit("setLoader", false);
          setTimeout(() => document.getElementById("formError").style.display = "none", 5000);
        }

      }
    }
  },
  clearOpportunityList({commit}) {
    commit("setOpportunities", []);
    commit("setNext", null);
    commit("setCurrentSearch", null);
    commit("setResultCount", null);
  },
  async performOpportunityCreate({commit}, payload) {
    const response = await api.opportunityCreate(payload);
    if (!response)  {
        commit("setFormError", "Something went wrong. Try again later");
        setTimeout(() => document.getElementById("formError").style.display = "none", 5000);
    }  else {
//      router.push(`/opportunity/${response.id}`);
      router.push(`/opportunity-success/${response.id}`);
    }
  },
  performOpportunityUpdate({commit}, payload) {
    const response = api.opportunityUpdate(payload)
    if (!response)  {
      commit("setFormError", "Something went wrong. Try again later");
      setTimeout(() => document.getElementById("formError").style.display = "none", 5000);
    }  else {
        router.push(`/opportunity-success/${payload.id}`);
      }

  },
  async fetchOpportunityData({commit, dispatch}, payload) {
    const response = await api.opportunityData(payload.id);
    if (!response)  {
        commit("setFormError", "Something went wrong. Try again later")
    } else {
      if (response.opportunity_predecessors) {
        commit("setPredecessors", response.opportunity_predecessors)
      } else {
        commit("setPredecessors", null)
      }
      if (response.opportunity_conflicts) {
        commit("setConflicts", response.opportunity_conflicts)
      } else {
        commit("setConflicts", null)
      }
      commit("setOpportunityData", response);
      dispatch ("fetchOpportunityComments", response.opportunity_number);
    }
  },
  async fetchOpportunityStatusDetails({commit}, payload) {
    const response = await api.opportunityStatusDetails(payload);
    if (!response)  {
        commit("setFormError", "Something went wrong. Try again later")
    } else {
      commit("setOpportunityStatusDetails", response);
    }
  },

  async performChangeOpportunityStatusDetails({commit}, payload) {
    const response = await api.changeOpportunityStatusDetails(payload)
    if (!response)  {
      commit("setFormError", "Something went wrong. Try again later");
      setTimeout(() => document.getElementById("formError").style.display = "none", 5000);
    } else {
      router.push(`/opportunity-success/${payload.opportunity_id}`);
    }
  },
  async performCreatePredecessorRelation({commit}, payload) {
    const response = await api.createPredecessorRelation(payload)
    if (!response)  {
      commit("setFormError", "Something went wrong. Try again later");
      setTimeout(() => document.getElementById("formError").style.display = "none", 5000);
    } else {
      router.push(`/opportunity-success/${payload.id}`);
    }
  },
  async performCreateConflictRelation({commit}, payload) {
    const response = await api.createConflictRelation(payload)
    if (!response)  {
      commit("setFormError", "Something went wrong. Try again later");
      setTimeout(() => document.getElementById("formError").style.display = "none", 5000);
    } else {
      router.push(`/opportunity-success/${payload.id}`);
    }
  },
  async fetchSiteView({commit, dispatch}, payload) {
    const response = await api.siteView(payload.id);
    if (!response)  {
        commit("setFormError", "Something went wrong. Try again later");
        setTimeout(() => document.getElementById("formError").style.display = "none", 5000);
    } else {
      commit("setSiteData", response);
      dispatch ("fillSavingsChart", response.seu_savings_eur_dict);
      const savingsHeight = 100;
      commit("setSavingsChartHeight", savingsHeight);
      dispatch ("fillCo2Chart", response.seu_co2_tonnes_dict);
      const co2Height = 100;
      commit("setCo2ChartHeight", co2Height);
      dispatch ("fillCapitalInvestmentChart", response.seu_capital_investment_eur_dict);
      const capitalInvestmentHeight = Object.keys(response.seu_capital_investment_eur_dict).length * (5 + ((20 - Object.keys(response.seu_capital_investment_eur_dict).length) / 2.5));
      commit("setCapitalInvestmentChartHeight", capitalInvestmentHeight);
    }
  },
  async fetchSiteSeuView({commit}, payload) {
    const response = await api.siteSeuView(payload.site_id, payload.seu_id);
    if (!response)  {
      commit("setFormError", "Something went wrong. Try again later");
      setTimeout(() => document.getElementById("formError").style.display = "none", 5000);
    } else {
      commit("setSiteSeuData", response);
    }
  },
  fillSavingsChart({ commit }, dataset) {
    const dataLabelsSavingsChart = Object.keys(dataset);
    const dataValuesSavingsChart = Object.values(dataset);
    const chartData = {
      labels: dataLabelsSavingsChart,
      datasets: [
        {
            backgroundColor: ["#59a14f","#59a14f","#59a14f", "#59a14f","#59a14f", "#59a14f","#59a14f","#59a14f",
                             "#59a14f", "#59a14f","#59a14f", "#59a14f", "#59a14f", "#59a14f", "#59a14f",
                             "#59a14f", "#59a14f","#59a14f", "#59a14f", "#59a14f"],
          data: dataValuesSavingsChart
        }
      ]
    };
    commit("setSavingsChartData", chartData)
  },
  fillCo2Chart({ commit }, dataset) {
    const dataLabelsCo2Chart = Object.keys(dataset);
    const dataValuesCo2Chart = Object.values(dataset);
    const chartData = {
      labels: dataLabelsCo2Chart,
      datasets: [
        {
            backgroundColor: ["#6d9cad","#6d9cad","#6d9cad", "#6d9cad","#6d9cad", "#6d9cad","#6d9cad","#6d9cad",
                             "#6d9cad","#6d9cad","#6d9cad", "#6d9cad","#6d9cad", "#6d9cad","#6d9cad","#6d9cad",
                             "#6d9cad","#6d9cad","#6d9cad", "#6d9cad","#6d9cad", "#6d9cad","#6d9cad","#6d9cad",],
          data: dataValuesCo2Chart
        }
      ]
    };
    commit("setCo2ChartData", chartData)
  },
  fillCapitalInvestmentChart({ commit }, dataset) {
    const dataLabelsCapitalInvestmentChart = Object.keys(dataset);
    const dataValuesCapitalInvestmentChart = Object.values(dataset);
    const chartData = {
      labels: dataLabelsCapitalInvestmentChart,
      datasets: [
        {
            backgroundColor: ["#bf9f60","#bf9f60","#bf9f60", "#bf9f60","#bf9f60", "#bf9f60","#bf9f60","#bf9f60",
                             "#bf9f60","#bf9f60","#bf9f60", "#bf9f60","#bf9f60", "#bf9f60","#bf9f60","#bf9f60",
                             "#bf9f60","#bf9f60","#bf9f60", "#bf9f60","#bf9f60", "#bf9f60","#bf9f60","#bf9f60",],
          data: dataValuesCapitalInvestmentChart
        }
      ]
    };
    commit("setCapitalInvestmentChartData", chartData)
  },
  async performOpportunityLogEntryCreate({commit}, payload) {
    const response = await api.opportunityLogEntryCreate(payload);
    if (!response)  {
        commit("setFormError", "Something went wrong. Try again later");
        setTimeout(() => document.getElementById("formError").style.display = "none", 5000);
    } else {
        commit("setLoader", false);
    }
  },
  async performChangeOpportunityStatus({commit}, payload) {
    const response = await api.changeOpportunityStatus(payload)
    if (!response)  {
      commit("setFormError", "Something went wrong. Try again later");
      setTimeout(() => document.getElementById("formError").style.display = "none", 5000);
    } else {
      router.push(`/opportunity-success/${payload.id}`);
    }
  },
  async fetchOpportunityComments({commit}, payload) {
    const response = await api.opportunityComments(payload);
    commit("setLoader", true);
    if (!response)  {
        commit("setLoader", false);
    } else {
      commit("setOpportunityComments", response.comments);
      commit("setLoader", false);
    }
  },
  async performCreateOpportunityComment({commit}, payload) {
    const response = await api.createOpportunityComment(payload)
    if (!response)  {
      commit("setFormError", "Something went wrong. Try again later");
      setTimeout(() => document.getElementById("formError").style.display = "none", 5000);
    } else {
      router.push(`/opportunity-success/${payload.id}`);
    }
  },
  clearComments({commit}) {
    commit("setOpportunityComments", null);
  },
  async fetchOpportunityIdData({commit, dispatch}, payload) {
    const response = await api.opportunityId(payload)
    if (!response)  {
      commit("setFormError", "Something went wrong. Try again later");
      setTimeout(() => document.getElementById("formError").style.display = "none", 5000);
    } else {
      commit("setOpportunityId", response.id);
      dispatch("fetchOpportunityData", {"id": response.id});
      dispatch("fetchOpportunityStatusDetails", response.id)
    }
  },
};


const mutations = {
  setOpportunities:  (state, opportunities) => {
    state.opportunities = opportunities;
  },
  setArchivedOpportunities:  (state, archivedOpportunities) => {
    state.archivedOpportunities = archivedOpportunities;
  },
  setOpportunityData: (state, opportunityData) => {
    state.opportunityData = opportunityData;
  },
  setOpportunityId: (state, opportunityId) => {
    state.opportunityId = opportunityId
  },
  setPredecessors: (state, predecessors) => {
    state.predecessors = predecessors
  },
  setConflicts: (state, conflicts) => {
    state.conflicts = conflicts
  },
  setSiteData: (state, siteData) => {
    state.siteData = siteData
  },
  setSavingsChartData: (state, savingsChartData) => {
    state.savingsChartData = savingsChartData
  },
  setSavingsChartHeight: (state, savingsChartHeight) => {
    state.savingsChartHeight = savingsChartHeight
  },
  setCo2ChartData: (state, co2ChartData) => {
    state.co2ChartData = co2ChartData
  },
  setCo2ChartHeight: (state, co2ChartHeight) => {
    state.co2ChartHeight = co2ChartHeight
  },
  setCapitalInvestmentChartData: (state, capitalInvestmentChartData) => {
    state.capitalInvestmentChartData = capitalInvestmentChartData
  },
  setCapitalInvestmentChartHeight: (state, capitalInvestmentChartHeight) => {
    state.capitalInvestmentChartHeight = capitalInvestmentChartHeight
  },
  setSiteSeuData: (state, siteSeuData) => {
    state.siteSeuData = siteSeuData
  },
  setSavingsSum: (state, savingsSum) => {
    state.savingsSum = savingsSum
  },
  setCo2TonnesSum: (state, co2TonnesSum) => {
    state.co2TonnesSum = co2TonnesSum
  },
  setCapitalInvestmentSum: (state, capitalInvestmentSum) => {
    state.capitalInvestmentSum = capitalInvestmentSum
  },
  setOpportunityStatusDetails: (state, opportunityStatusDetails) => {
    state.opportunityStatusDetails = opportunityStatusDetails
  },
  setOpportunityComments: (state, opportunityComments) => {
    state.opportunityComments = opportunityComments
  },
};


export default {
  state,
  getters,
  actions,
  mutations
}