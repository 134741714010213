import api from "../../api/api.js"
import router from "../../router.js"

const state = {
  companies: null,
  resultCount: null,
  currentSearch: null,
  loader: false,
  next: null,
  formError: null,
  sitesAndSeu: null,
  sitesAndSeuAndOpp: null,
  siteSeu: null,
  opportunitiesSites: null,
  nestedSitesAndSeu: null,
  serviceSitesAndSeu: null,
  actionTypes: null,
  eventTypes: null,
  costIntervals: null,
  opportunityStatusList: null,
  permittedSites: null,
  siteNames: null,
  siteCompanyNames: null,
  plansCompanies: null,
  plansSites: null,
  archivePlansSites: null,
  nestedCompanySites: null,
  currencies: null,
  siteServices: null,
  nestedSitesAndDashboardDetails: null,
};


const getters = {
  getCompanies: state => state.companies,
  getResultCount: state => state.resultCount,
  getCurrentSearch: state => state.currentSearch,
  getLoader: state => state.loader,
  getNext: state => state.next,
  getFormError: state => state.formError,
  getSiteSeu: state => state.siteSeu,
  getSitesAndSeu: state => state.sitesAndSeu,
  getSitesAndSeuAndOpp: state => state.sitesAndSeuAndOpp,
  getOpportunitiesSites: state => state.opportunitiesSites,

  getNestedSitesAndSeu: state => state.nestedSitesAndSeu,
  getServiceSitesAndSeu: state => state.serviceSitesAndSeu,
  getNestedSitesAndDashboardDetails: state => state.nestedSitesAndDashboardDetails,

  getActionTypes: state => state.actionTypes,
  getEventTypes: state => state.eventTypes,
  getCostIntervals: state => state.costIntervals,
  getOpportunityStatusList: state => state.opportunityStatusList,

  getPermittedSites: state => state.permittedSites,

  getSiteNames: state => state.siteNames,
  getSiteCompanyNames: state => state.siteCompanyNames,

  getPlansCompanies: state => state.plansCompanies,
  getPlansSites: state => state.plansSites,
  getArchivePlansSites: state => state.archivePlansSites,
  getNestedCompanySites: state => state.nestedCompanySites,
  getCurrencies: state => state.currencies,
  getSiteServices: state => state.siteServices

};


const actions = {
  async fetchNestedSitesAndDashboardDetails({commit}) {
    const response = await api.nestedSitesAndDashboardDetails();
    if (!response)  {
      commit("setFormError", "Something went wrong. Try again later");
      setTimeout(() => document.getElementById("formError").style.display = "none", 5000);
    } else {
      commit("setNestedSitesAndDashboardDetails", response.queryset);
    }
  },
  async fetchCompanySites({commit}, company) {
    const response = await api.companySites(company);
    commit("setCompanySites", response.sites);
  },
  async fetchCompanies({commit}) {
    const response = await api.companies();
    commit("setCompanies", response.companies);
  },
  async fetchCurrencies({commit}) {
    const response = await api.currencies();
    commit("setCurrencies", response.currencies);
  },
  async fetchSiteServices({commit}, payload) {
    const response = await api.siteServices(payload);
    commit("setSiteServices", response);
  },
  async performUpdateSiteServices({commit}, payload) {
    const response = await api.updateSiteServices(payload)
    if (!response)  {
      commit("setFormError", "Something went wrong. Try again later");
      setTimeout(() => document.getElementById("formError").style.display = "none", 5000);
    } else {
      router.push(`/site-choice`);
    }
  },
  async fetchSiteNames({commit}) {
    const response = await api.siteNames();
    commit("setSiteNames", response.site_names);
  },
   async fetchSiteCompanyNames({commit}) {
    const response = await api.siteCompanyNames();
    commit("setSiteCompanyNames", response.site_names);
  },
  async fetchPermittedSites({commit}) {
    const response = await api.permittedSites();
    commit("setPermittedSites", response.site_permissions);
  },
  async fetchSitesAndSeu({commit}) {
    const response = await api.sitesAndSeu();
    commit("setSitesAndSeu", response);
  },
  async fetchSitesAndSeuAndOpp({commit}) {
    const response = await api.sitesAndSeuAndOpp();
    commit("setSitesAndSeuAndOpp", response);
  },
  async fetchOpportunitiesSites({commit}) {
    const response = await api.opportunitiesSites();
    commit("setOpportunitiesSites", response);
  },
  async fetchNestedCompanySites({commit}) {
    const response = await api.nestedCompanySites();
    if (!response)  {
      commit("setFormError", "Something went wrong. Try again later");
      setTimeout(() => document.getElementById("formError").style.display = "none", 5000);
    } else {
      commit("setNestedCompanySites", response.queryset);
    }
  },
  async fetchNestedSitesAndSeu({commit}) {
    const response = await api.nestedSitesAndSeu();
    if (!response)  {
      commit("setFormError", "Something went wrong. Try again later");
      setTimeout(() => document.getElementById("formError").style.display = "none", 5000);
    } else {
      commit("setNestedSitesAndSeu", response.queryset);
    }
  },
  async fetchServiceSitesAndSeu({commit}) {
    const response = await api.serviceSitesAndSeu();
    if (!response)  {
      commit("setFormError", "Something went wrong. Try again later");
      setTimeout(() => document.getElementById("formError").style.display = "none", 5000);
    } else {
      commit("setServiceSitesAndSeu", response.queryset);
    }
  },
  async fetchSiteSeu({commit}, payload) {
    const response = await api.siteSeuList(payload.id);
    if (!response)  {
      commit("setFormError", "Something went wrong. Try again later");
      setTimeout(() => document.getElementById("formError").style.display = "none", 5000);
    } else {
      commit("setSiteSeu", response.seu);
    }
  },

  async fetchActionTypes({commit}) {
    const response = await api.actionTypes();
    if (!response)  {
      commit("setFormError", "Something went wrong. Try again later");
      setTimeout(() => document.getElementById("formError").style.display = "none", 5000);
    } else {
      commit("setActionTypes", response.results);
    }
  },
  async fetchEventTypes({commit}) {
    const response = await api.eventTypes();
    if (!response)  {
      commit("setFormError", "Something went wrong. Try again later");
      setTimeout(() => document.getElementById("formError").style.display = "none", 5000);
    } else {
      commit("setEventTypes", response.results);
    }
  },
  async fetchCostIntervals({commit}) {
    const response = await api.costIntervals();
    if (!response)  {
      commit("setFormError", "Something went wrong. Try again later");
      setTimeout(() => document.getElementById("formError").style.display = "none", 5000);
    } else {
      commit("setCostIntervals", response.results);
    }
  },
  async fetchOpportunityStatusList({commit}) {
    const response = await api.opportunityStatusList();
    if (!response)  {
      commit("setFormError", "Something went wrong. Try again later");
      setTimeout(() => document.getElementById("formError").style.display = "none", 5000);
    } else {
      commit("setOpportunityStatusList", response.results);
    }
  },
  async fetchPlansCompanies({commit}) {
    const response = await api.plansCompanies();
    commit("setPlansCompanies", response.companies);
  },
  async fetchPlansSites({commit}) {
    const response = await api.plansSites();
    commit("setPlansSites", response.sites);
  },
  async fetchArchivePlansSites({commit}) {
    const response = await api.archivePlansSites();
    commit("setArchivePlansSites", response.sites);
  },
  performSetFormError({commit}, error) {
    commit("setFormError", error);
  },
  performSetLoader({commit}, loader) {
    commit("setLoader", loader);
  },
};


const mutations = {
  setCompanySites: (state, companySites) => {
    state.companySites = companySites
  },
  setCompanies: (state, companies) => {
    state.companies = companies
  },
  setSiteNames: (state, siteNames) => {
    state.siteNames = siteNames;
  },
  setSiteServices: (state, siteServices) => {
    state.siteServices = siteServices;
  },
  setSiteCompanyNames: (state, siteCompanyNames) => {
    state.siteCompanyNames = siteCompanyNames;
  },
  setResultCount: (state, resultCount) => {
    state.resultCount = resultCount;
  },
  setLoader: (state, loader) => {
    state.loader = loader;
  },
  setNext: (state, next) => {
    state.next = next
  },
  setCurrentSearch: (state, currentSearch) => {
    state.currentSearch = currentSearch
  },
  setFormError: (state, formError) => {
    state.formError = formError
  },
  setSitesAndSeu: (state, sitesAndSeu) => {
    state.sitesAndSeu = sitesAndSeu
  },
  setSitesAndSeuAndOpp: (state, sitesAndSeuAndOpp) => {
    state.sitesAndSeuAndOpp = sitesAndSeuAndOpp
  },
  setNestedSitesAndSeu: (state, nestedSitesAndSeu) => {
    state.nestedSitesAndSeu = nestedSitesAndSeu
  },
  setServiceSitesAndSeu: (state, serviceSitesAndSeu) => {
    state.serviceSitesAndSeu = serviceSitesAndSeu
  },
  setSiteSeu: (state, siteSeu) => {
    state.siteSeu = siteSeu
  },
  setOpportunitiesSites: (state, opportunitiesSites) => {
    state.opportunitiesSites = opportunitiesSites
  },

  setEventTypes: (state, eventTypes) => {
    state.eventTypes = eventTypes;
  },
  setActionTypes: (state, actionTypes) => {
    state.actionTypes = actionTypes;
  },
  setCostIntervals: (state, costIntervals) => {
    state.costIntervals = costIntervals;
  },
  setOpportunityStatusList: (state, opportunityStatusList) => {
    state.opportunityStatusList = opportunityStatusList;
  },
  setPermittedSites: (state, permittedSites) => {
    state.permittedSites = permittedSites
  },
  setPlansCompanies: (state, plansCompanies) => {
    state.plansCompanies = plansCompanies
  },
  setPlansSites: (state, plansSites) => {
    state.plansSites = plansSites
  },
  setArchivePlansSites: (state, archivePlansSites) => {
    state.archivePlansSites = archivePlansSites
  },
  setNestedCompanySites: (state, nestedCompanySites) => {
    state.nestedCompanySites = nestedCompanySites
  },
  setCurrencies: (state, currencies) => {
    state.currencies = currencies
  },
  setNestedSitesAndDashboardDetails: (state, nestedSitesAndDashboardDetails) => {
    state.nestedSitesAndDashboardDetails = nestedSitesAndDashboardDetails
  },
};


export default {
  state,
  getters,
  actions,
  mutations
}